const videoData = [
  {
    id: "Luxury Weeding - Corinthia Hotel London",
    src: "https://player.vimeo.com/video/674980601?h=4d4102c4a9&color=ff0179&title=0&byline=0&portrait=0&playbar=0&sidedock=0&controls=0",
  },
  {
    id: "Something for windy",
    src: "https://player.vimeo.com/video/674980663?h=21d93adab8&color=ff0179&title=0&byline=0&portrait=0&playbar=0",
  },
  {
    id: "METAMORPHOSE",
    src: "https://player.vimeo.com/video/674980799?h=a515e560ef&color=ff0179&title=0&byline=0&portrait=0&playbar=0",
  },
  {
    id: "Sea-Side",
    src: "https://player.vimeo.com/video/674990076?h=fdf597e923&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&color=ff0179&title=0&byline=0&portrait=0&playbar=0",
  },
  {
    id: "Professional Portfolio Photoshoot",
    src: "https://player.vimeo.com/video/674990199?h=8ecbf8e026&color=ff0179&title=0&byline=0&portrait=0&playbar=0",
  },
  {
    id: "TNT Collab - Drum & Bass (Official Music Video)",
    src: "https://player.vimeo.com/video/674980830?h=2dbba6a942&amp;badge=0&amp;autopause=0&amp;player_id=0&amp&color=ff0179&title=0&byline=0&portrait=0&playbar=0",
  },
  {
    id: "Bertrand Contador - Forget Her (Official Music Video)",
    src: "https://player.vimeo.com/video/674989964?h=241e20d956&amp;badge=0&amp;autopause=0&amp;player_id=0&amp&color=ff0179&title=0&byline=0&portrait=0&playbar=0;app_id=58479",
  },
  {
    id: "Yerbury - Portrait of an Artist",
    src: "https://player.vimeo.com/video/699576972?h=e86eb2e1d4&amp;badge=0&amp;autopause=0&amp;player_id=0&amp&color=ff0179&title=0&byline=0&portrait=0&playbar=0;app_id=58479",
  },
]

export default videoData
