import React from "react";
import Layout from "../components/Layout";
import Seo from "../components/seo";
import videoData from "../components/data/videoData";
import styled from "styled-components";

import useCheckMobileScreen from "../components/hooks/useCheckMobileScreen";
import "@fontsource/nunito-sans";

const VideosPage = () => (
  <Layout descriptionText={"Videos"}>
    <Seo title="videos" />

    {videoData.map((item, index) => (
      <div key={index}>
        <Video videoSrcURL={item.src} videoTitle={index} />
        <VideoDescription>{item.id}</VideoDescription>
      </div>
    ))}
  </Layout>
);

export default VideosPage;

const Video = ({ videoSrcURL, videoTitle }) => {
  const isMobile = useCheckMobileScreen();
  const dimensions = isMobile
    ? { width: "370", height: "208" }
    : { width: "740", height: "416" };
  return (
    <div className="video">
      <iframe
        {...dimensions}
        src={videoSrcURL}
        title={videoTitle}
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        frameBorder="0"
        webkitallowfullscreen="true"
        mozallowfullscreen="true"
        allowFullScreen
      />
    </div>
  );
};

const VideoDescription = styled.div`
  font-size: 20px;
  color: black;
  font-family: "Nunito Sans";
  text-align: "center";
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  color: #7a7a7a;
`;
